import { dataLayerEvent } from '/machinery/tracking/dataLayerEvent'

export function trackDragStart({ title, index }) {
  dataLayerEvent('interaction_start', { interaction: { title, index, type: 'drag' } })
}

export function trackDragEnd({ title, index }) {
  dataLayerEvent('interaction_stop', { interaction: { title, index, type: 'drag' } })
}

export function trackNavigate({ title, index }) {
  dataLayerEvent('interaction_click', { interaction: { title, index, type: 'click' } })
}
